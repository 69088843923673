import React, { Component } from 'react';

class Breadcrumb extends Component {
    render() {
        return (
            <section className="breadcrumb-area overlay-dark d-flex align-items-center" style={{
                'backgroundImage': 'url(https://token-monitor.s3.amazonaws.com/1682575140955WechatIMG34927%20%281%29.png)',
                // 'height': '620px',
                // backgroundAttachment: 'fixed'
                backgroundPosition: '0px 0px'
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 className="m-0">鲲道Swap</h2>
                                <div className="breadcrumb d-flex justify-content-center">
                                    鲲道Swap是一个即将在Core链上上线的全新DApp，它将为用户带来无与伦比的交易体验和丰富多样的金融机会。
                                </div>
                                {/* 
                                <ul>
                                    <li>①：推广享受10代团队佣金，佣金秒到认购钱包地址</li>
                                    <li>②：直推10人获得TP CEO NFT一张</li>
                                    <li>③：不认购没有推广权限，一个地址只能购买一次</li>

                                </ul> */}
                                {/* <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}>
                                    <div>①：推广享受10代团队佣金，佣金秒到认购钱包地址</div>
                                    <div>②：直推5人获得TP CEO NFT一张</div>
                                    <div>③：不认购没有推广权限，一个地址只能购买一次</div>
                                </div> */}
                                <ol className="breadcrumb d-flex justify-content-center">
                                    {/* <li className="breadcrumb-item"></li> */}
                                    {/* <li className="breadcrumb-item">一：推广享受10代团队佣金，佣金秒到认购钱包地址</li> */}
                                    {/* <li className="breadcrumb-item">一：推广享受10代团队佣金，佣金秒到认购钱包地址</li> */}
                                    {/* <li className="breadcrumb-item"><a href="#">{this.props.subpage}</a></li> */}
                                    {/* <li className="breadcrumb-item active">{this.props.page}</li> */}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Breadcrumb;